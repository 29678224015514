<template>
  <div class="foot">
    <div class="content">
      <div class="copy">
        <span v-if="dataSource.technicalSupport">
          {{ $t("Home.TechnicalSupport") }}：{{ dataSource.technicalSupport }}
        </span>
        <a
          v-if="dataSource.filingInformation"
          href="https://beian.miit.gov.cn"
          target="_blank"
        >
          {{ dataSource.filingInformation }}
        </a>
        <a
          v-if="dataSource.recordNumber"
          :href="`http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=${dataSource.recordNumber}`"
          target="_blank"
        >
          公网安备 {{ dataSource.recordNumber }}号
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Foot",
  props: {
    dataSource: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
};
</script>

<style lang="less" scoped>
.foot {
  max-width: 1920px;
  margin: 0 auto;
  background-color: #2e2e2e;
  .content {
    .mixinWrap();
    .copy {
      border-top: 1px solid rgba(255, 255, 255, 0.11);
      line-height: 24px;
      font-size: 12px;
      padding: 33px 0;
      text-align: center;
      span,
      a {
        color: #808080;
        padding-left: 12px;
        &:first-child {
          padding-left: 0;
        }
      }
    }
  }
}
</style>
